export const MBACampusImmersionStaticData = {
  data: true,
  AssociatedProgramProp:[
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "46",
                "Heading": "Bachelor of Computer Applications with specialization in Data Engineering",
                "ProgramType": "UG_Program",
                "SpecializationText": "Specialization in Data Engineering",
                "PROGRAM_ID": "241",
                "Custom10PgType": "HARPU-HCL"
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "57",
                "Heading": "Master of Arts - Psychology",
                "ProgramType": "PG_Program",
                "SpecializationText": "Specialisation in Psychology",
                "PROGRAM_ID": "617",
                "Custom10PgType": "HARPU-Jaipur"
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "16",
                "Heading": "Master of Arts (Journalism and Mass Communication)",
                "ProgramType": "PG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "246",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "17",
                "Heading": "Master of Arts (Public Policy & Governance)",
                "ProgramType": "PG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "608",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "10",
                "Heading": "Master of Business Administration",
                "ProgramType": "PG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "440",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "14",
                "Heading": "Bachelors of Arts",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "244",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "41",
                "Heading": "Bachelors of Arts (General) Hindi Medium",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "244",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "39",
                "Heading": "Bachelors of Arts (General) in Kannada",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "244",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "37",
                "Heading": "Bachelors of Arts (General) in Malayalam",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "244",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "38",
                "Heading": "Bachelors of Arts (General) in Tamil",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "244",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "40",
                "Heading": "Bachelors of Arts (General) in Telugu",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "244",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "15",
                "Heading": "Bachelor of Arts (Journalism and Mass Communication)",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "242",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "3",
                "Heading": "Bachelor of Business Administration",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "240",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "13",
                "Heading": "Bachelor of Commerce",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "243",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "58",
                "Heading": "Bachelor of Commerce Honours",
                "ProgramType": "UG_Program",
                "SpecializationText": "Honours",
                "PROGRAM_ID": "612",
                "Custom10PgType": "HARPU-Jaipur"
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "11",
                "Heading": "Bachelor of Computer Applications",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "241",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "45",
                "Heading": "Bachelor of Business Administration with specialization in Data Analytics",
                "ProgramType": "UG_Program",
                "SpecializationText": "Specialization in Data Analytics",
                "PROGRAM_ID": "240",
                "Custom10PgType": "HARPU-HCL"
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "52",
                "Heading": "BCA WITH SPECIALIZATION IN DATA ENGINEERING",
                "ProgramType": "UG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "241",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "47",
                "Heading": "Bachelor of Computer Applications with specialization in Software Engineering",
                "ProgramType": "UG_Program",
                "SpecializationText": "Specialization in Software Engineering",
                "PROGRAM_ID": "241",
                "Custom10PgType": "HARPU-HCL"
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "18",
                "Heading": "Master of Commerce (Financial Management)",
                "ProgramType": "PG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "245",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "12",
                "Heading": "Master of Computer Applications",
                "ProgramType": "PG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "515",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "56",
                "Heading": "Master of Science (Data Science)",
                "ProgramType": "PG_Program",
                "SpecializationText": "Data Science",
                "PROGRAM_ID": "613",
                "Custom10PgType": "HARPU-Jaipur"
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "54",
                "Heading": "MCA  WITH SPECIALIZATION IN CYBER SECURITY",
                "ProgramType": "PG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "515",
                "Custom10PgType": null
            }
        }
    },
    {
        "__typename": "ProgramDetailsPageEntity",
        "attributes": {
            "__typename": "ProgramDetailsPage",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "49",
                "Heading": "Master of Computer Applications",
                "ProgramType": "PG_Program",
                "SpecializationText": "Specialization in Software Engineering",
                "PROGRAM_ID": "515",
                "Custom10PgType": "HARPU-HCL"
            }
        }
    },
    {
        "__typename": "SpecializationEntity",
        "attributes": {
            "__typename": "Specialization",
            "BasicDetails": {
                "__typename": "ComponentProgramDetailsBasicDetails",
                "id": "28",
                "Heading": "Master of Computer Applications with specialization in Blockchain Technology and Management",
                "ProgramType": "PG_Program",
                "SpecializationText": null,
                "PROGRAM_ID": "515",
                "Custom10PgType": null
            }
        }
    }
]
,
CareerServices:{
  "enabled": true,
  "Title": {
      "enabled": true,
      "text": "Holistic Career Services: How we help you build your dream career"
  },
  "Subtitle": {
      "enabled": true,
      "text": "Our wide range of services are designed to meet your specific needs for career"
  },
  "CTA": {
      "text": "REGISTER NOW",
      "title": "REGISTER NOW TEST",
      "enabled": false
  },
  "ImageList": [
      {
          "enabled": true,
          "Title": "Career exploration tools",
          "Subtitle": "- Assessing your interests and strengths\n- Exploring different career options and pathways\n- Identifying your passion and potential",
          "Image": {
              "data": {
                  "attributes": {
                      "url": "https://amityonline.com/_s/image_10134_d8c4e7eb2f_bdbadeccfb.webp",
                      "alternativeText": null
                  }
              }
          }
      },
      {
          "enabled": true,
          "Title": "Resume/CV assistance",
          "Subtitle": "- Crafting professional resumes and CVs\n- Customizing application  for specific industries\n- Highlighting your skills and achievements effectively",
          "Image": {
              "data": {
                  "attributes": {
                      "url": "https://amityonline.com/_s/image_10135_6040bd8742_48391742c0.webp",
                      "alternativeText": null
                  }
              }
          }
      },
      {
          "enabled": true,
          "Title": "Virtual job search support",
          "Subtitle": "- Guiding through the online application process\n- Networking support via virtual events and platforms\n- Preparing for virtual interviews to establish a professional online presence",
          "Image": {
              "data": {
                  "attributes": {
                      "url": "https://amityonline.com/_s/image_10136_ec714781ff_c2aa81da6b.webp",
                      "alternativeText": null
                  }
              }
          }
      },
      {
          "enabled": true,
          "Title": "Career counselling & advising",
          "Subtitle": "- 1-on-1 sessions with experienced counsellors\n- Personalized career assessments and goal setting\n- Guidance on making informed decisions",
          "Image": {
              "data": {
                  "attributes": {
                      "url": "https://amityonline.com/_s/image_10137_4d740ef08a_01c1a85249.webp",
                      "alternativeText": null
                  }
              }
          }
      }
  ]
}
,
Faculties :{
  "data": [
      {
      "id": "1",
      "attributes": {
          "Name": "Dr Sunil Kumar",
          "enabled": true,
          "ShortDesc": "Hello, I'm Dr. Sunil Kumar, a PhD holder in Management as well as an engineer. I'm your faculty at Amity University, where I specialize in management courses such as research and production operation management.",
          "Department": "Assistant Professor",
          "Designation": "10+ years of experience; P.hD. in Management",
          "BorderColor": "#5DCA7B",
          "image": "https://amityonline.com/_s/4_5e42a2ff13.webp"
      }
      },
      {
      "id": "3",
      "attributes": {
          "Name": "Neha Tandon",
          "enabled": true,
          "ShortDesc": "Hello I'm Neha Tandon, at Amity University, we prioritize delivering an exceptional learning experience. Through immersive learning and cutting-edge technology like AI-powered professors, we ensure top-notch education.",
          "Department": "Assistant Professor",
          "Designation": "8+ years of experience; Double PG in Management & Commerce",
          "BorderColor": "#FF3E6C",
          "image": "https://amityonline.com/_s/0_I8_A4048_1_892178b2a2.png"
      }
      },
      {
      "id": "4",
      "attributes": {
          "Name": "Dr Winnie Sharma",
          "enabled": true,
          "ShortDesc": "Greetings, I'm Dr. Winnie Sharma, an Associate Professor in Political Science. With a PhD from Jamia Millia Islamia, my expertise lies in political ideology and secularism, particularly within Indian government and politics.",
          "Department": "Assistant Professor",
          "Designation": "8+ years of experience; Ph.D. in Political Science",
          "BorderColor": "#4194F4",
          "image": "https://amityonline.com/_s/3_de4aa40408.webp"
      }
      },
      {
      "id": "5",
      "attributes": {
          "Name": "Vandana Chandel",
          "enabled": true,
          "ShortDesc": "Welcome to Amity University Online! I'm Vandana Chandel, your coordinator for BJMC and MJMC. With 14 years in media, I bring industry expertise. At Amity, we offer rare education, prioritizing student care, flexible learning.",
          "Department": "Senior Teaching Associate",
          "Designation": "13+ years of experience with BBC, AIR, ANI, Zee and more",
          "BorderColor": "#5DCA7B",
          "image": "https://amityonline.com/_s/5_752010f486.webp"
      }
      },
      {
      "id": "7",
      "attributes": {
          "Name": "Dr Sukanya Das",
          "enabled": true,
          "ShortDesc": "Hello, I'm Dr. Sunil Kumar, a PhD holder in Management as well as an engineer. I'm your faculty at Amity University, where I specialize in management courses such as research and production operation management.",
          "Department": "Associate Professor",
          "Designation": "14+ years of experience; Ph.D. in Sociology",
          "BorderColor": "#FF3E6C",
          "image": "https://amityonline.com/_s/1_2e5f516f65.webp"
      }
      },
      {
      "id": "11",
      "attributes": {
          "Name": "Dr Richa Yadav",
          "enabled": true,
          "ShortDesc": "Welcome to Amity University! I'm Dr. Richa Yadav, Associate Professor of IT at Amity University Online. With a PhD in Computer Science, my focus is on cloud computing. As program coordinator for BCA and MCA.",
          "Department": "Associate Professor",
          "Designation": "14+ years of experience in CS & IT; Ph.D. in Cloud Computing",
          "BorderColor": "#5DCA7B",
          "image": "https://amityonline.com/_s/Richa_Yadav_f3d8a67f07.png"
      }
      },
      {
      "id": "10",
      "attributes": {
          "Name": "Sachit Paliwal",
          "enabled": true,
          "ShortDesc": "Hello, my name is Sachit Paliwal. I work as an Assistant Professor and Program Coordinator of BCom at Amity University Online. My area of specialization is finance and accounting, and my research includes stock market integration, portfolio analysis.",
          "Department": "Assistant Professor",
          "Designation": "12+ years of experience; MBA in Finance Management",
          "BorderColor": "#5DCA7B",
          "image": "https://amityonline.com/_s/Sachit_Paliwal_71d1a4df8d.png"
      }
      },
      {
      "id": "8",
      "attributes": {
          "Name": "Dr Hailey Stanton",
          "enabled": true,
          "ShortDesc": "Hello, I'm Dr. Hailey Stanton, an expert in travel, tours, and management. Excited to join Amity University, I love teaching tourism, geography, and transportology. Aviation and sustainability are my passions.",
          "Department": "International Faculty",
          "Designation": "11+ years of experience; Ph.D. from Coventry University",
          "BorderColor": "#5DCA7B",
          "image": "https://amityonline.com/_s/q2_ed8f504ce2.webp"
      }
      },
      {
      "id": "6",
      "attributes": {
          "Name": "Dr Ron Darnell",
          "enabled": true,
          "ShortDesc": "I'm Dr. Ronald Darnell, a professor at Amity University. I hold a Bachelor of Science from the University of Texas, an MBA in Management Information Systems (MIS) from the University of Dallas, and a PhD in Organization and Information Technology.",
          "Department": "International Faculty",
          "Designation": "Ph.D. from Capella University; MBA from University of Texas",
          "BorderColor": "#4194F4",
          "image": "https://amityonline.com/_s/q1_514b1b86e6.webp"
      }
      },
      {
      "id": "9",
      "attributes": {
          "Name": "Luke Pearce",
          "enabled": true,
          "ShortDesc": "Hello, I'm Luke Pearce. I've taught English in the UK, Spain, and Australia for several years. With a background in Spanish concepts from the University of Exeter, I obtained a PGCE qualification and recently completed my master's in Educational Language.",
          "Department": "International Faculty",
          "Designation": "10+ years of experience; Master’s in Education and Language",
          "BorderColor": "#FF3E6C",
          "image": "https://amityonline.com/_s/q3_9a344efcd1.webp"
      }
      }
  ]
}

,
  CampusDetails: {
    __typename: "ComponentPagesAdvantages",
    id: "90",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      id: "5276",
      enabled: true,
      text: "Campuses Under This Program",
    },
    Subtitle: null,
    ImageList: [
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "873",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Delhi-NCR.webp",
              alternativeText: null,
              url: "https://amityonline.com/_sb/Delhi_NCR_aa77f8939d.webp",
            },
          },
        },
        Title: "Noida Campus (Sector-125)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "874",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Kolkata.webp",
              alternativeText: null,
              url: "https://amityonline.com/_sb/Kolkata_d464b8eae0.webp",
            },
          },
        },
        Title: "Kolkata Campus (New Town)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "876",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Mumbai.webp",
              alternativeText: null,
              url: "https://amityonline.com/_s/mumbai_1ffd81e80b.webp",
            },
          },
        },
        Title: "Mumbai City Campus (Malad)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "877",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Patna.webp",
              alternativeText: null,
              url: "https://amityonline.com/_sb/Patna_edab4d7065.webp",
            },
          },
        },
        Title: "Patna Campus (Rupaspur)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "878",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Ahmedabad.webp",
              alternativeText: null,
              url: "https://amityonline.com/_s/ahmedabad_03823a35e2.webp",
            },
          },
        },
        Title: "Ahmedabad Campus (Bodakdeo)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "879",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Chennai.webp",
              alternativeText: null,
              url: "https://amityonline.com/_sb/Chennai_00838b929f.webp",
            },
          },
        },
        Title: "Chennai Campus (Nungambakkam)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "880",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Banglore.webp",
              alternativeText: null,
              url: "https://amityonline.com/_s/banglore_b48f6e8af7.jpg",
            },
          },
        },
        Title: "Bengaluru City Campus (Koramangala)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "881",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Hyderabad.webp",
              alternativeText: null,
              url: "https://amityonline.com/_sb/Hyderabad_b7705b3ec9.webp",
            },
          },
        },
        Title: "Hyderabad Campus (Punjagutta)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "882",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Indore.webp",
              alternativeText: null,
              url: "https://amityonline.com/_s/indore_caa5cf3f01.jpg",
            },
          },
        },
        Title: "Indore Campus (Bicholi Mardana Road)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "883",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "Pune.webp",
              alternativeText: null,
              url: "https://amityonline.com/_sb/Pune_1aeb72fab3.webp",
            },
          },
        },
        Title: "Pune Campus (Shivajinagar)",
        Subtitle: " ",
        enabled: true,
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        id: "1212",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              name: "jaipur.webp",
              alternativeText: null,
              url: "https://amityonline.com/_sb/jaipur_2baac879a0.webp",
            },
          },
        },
        Title: "Jaipur Campus (Kant Kalwar)",
        Subtitle: " ",
        enabled: true,
      },
    ],
  },
  BannerSlider: {
    enabled: true,
    Heading: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "MBA With Campus Immersion (For Executives)",
      noteText: "NOTE- ONLY FOR STUDENTS RESIDING IN INDIA",
    },
    Duration: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "2 years",
    },
    ProgramType: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Online PG Program",
    },
    CTA: {
      __typename: "ComponentCommonButtonWithImg",
      enabled: true,
      text: "Download brochure",
      Icon: {
        __typename: "UploadFileEntityResponse",
        data: {
          __typename: "UploadFileEntity",
          attributes: {
            __typename: "UploadFile",
            url: "https://amityonline.com/_s/download_arrow_1104bfe734.png",
            alternativeText: null,
            name: "download-arrow.png",
          },
        },
      },
    },
    Image: {
      __typename: "ComponentCommonImage",
      enabled: true,
      source: {
        __typename: "UploadFileRelationResponseCollection",
        data: [
          {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_sb/desktop_818a74a838.webp",
            },
          },
        ],
      },
      mobileSource: {
        __typename: "UploadFileEntityResponse",
        data: {
          __typename: "UploadFileEntity",
          attributes: {
            __typename: "UploadFile",
            url: "https://amityonline.com/_sb/mobile_bb423da0c0.webp",
          },
        },
      },
      alt: "mba",
      title: "MBA With Campus Immersion (For Executives)",
    },
    Sponsor: null,
  },
  AnnouncementData: {
    __typename: "ComponentProgramDetailsAnnouncement",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Admissions\nopen",
      ckrich: null,
    },
    SubtitleLabel: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "For July'25\nsession",
    },
    Subtitle: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "",
    },
    titleDescription: null,
    BGColor: "#FF3E6C",
    isOutline: null,
    fontSizeTitle: null,
    fontSizeSubtitleLabel: null,
    fontSizeSubtitleText: null,
    fontSizeDescription: null,
    textColor: null,
    Image: {
      __typename: "ComponentCommonImage",
      enabled: true,
      source: {
        __typename: "UploadFileRelationResponseCollection",
        data: [
          {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/icon1_8608611963.webp",
            },
          },
        ],
      },
      alt: "admission-date",
      title: "Admission end \ndate:",
    },
  },
  Attractions: [
    {
      __typename: "ComponentProgramDetailsAnnouncement",
      enabled: true,
      Title: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Admissions\nopen",
        ckrich: null,
      },
      SubtitleLabel: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "For July'25 session",
      },
      Subtitle: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "",
      },
      titleDescription: null,
      BGColor: "#FF3E6C",
      isOutline: null,
      fontSizeTitle: null,
      fontSizeSubtitleLabel: null,
      fontSizeSubtitleText: null,
      fontSizeDescription: null,
      TextColor: "#ffffff",
      Image: {
        __typename: "ComponentCommonImage",
        enabled: true,
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/icon1_8608611963.webp",
              },
            },
          ],
        },
        alt: "admission-date",
        title: "Admission end \ndate:",
      },
    },
    {
      __typename: "ComponentPagesAttractions",
      enabled: true,
      Country: "IN",
      Title: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "₹15,835",
        ckrich: null,
      },
      TitleDescription: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "per month",
      },
      Content: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Starting EMI*",
      },
      BorderColor: "#5DCA7B",
      Image: {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/icon2_56fb744843.webp",
              },
            },
          ],
        },
        alt: "EMI",
        enabled: true,
        title: "Starting EMI*",
      },
      textColor: null,
      fontSizeDescription: null,
      fontSizeTitle: null,
      fontSizeSubtitle: null,
      isOutline: true,
    },
    {
      __typename: "ComponentPagesAttractions",
      enabled: true,
      Country: null,
      Title: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "200 +",
        ckrich: null,
      },
      TitleDescription: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: null,
      },
      Content: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Hours Of On-Campus Learning",
      },
      BorderColor: "#4194F4",
      Image: {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/icon3_f57565e840.webp",
              },
            },
          ],
        },
        alt: "course-credits",
        enabled: true,
        title: "course-credits",
      },
      textColor: null,
      fontSizeDescription: null,
      fontSizeTitle: null,
      fontSizeSubtitle: null,
      isOutline: true,
    },
    {
      __typename: "ComponentPagesAttractions",
      enabled: true,
      Country: null,
      Title: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "10+",
        ckrich: null,
      },
      TitleDescription: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: null,
      },
      Content: {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Campuses To \nChoose From",
      },
      BorderColor: "#FFC907",
      Image: {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/Screenshot_2025_01_03_at_7_26_43_PM_79addcfbb4.webp",
              },
            },
          ],
        },
        alt: "courses",
        enabled: true,
        title: "courses",
      },
      textColor: null,
      fontSizeDescription: null,
      fontSizeTitle: null,
      fontSizeSubtitle: null,
      isOutline: true,
    },
  ],
  SecondaryMenu: {
    __typename: "ComponentProgramDetailsSecondaryMenu",
    enabled: true,
    Text: [
      {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Overview",
        ckrich: "Overview",
      },
      {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "CAMPUSES",
        ckrich: "Campuses",
      },
      {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Program Highlights",
        ckrich: "ProgramHighlights",
      },
      {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Curriculum",
        ckrich: 'CourseCurriculum',
      },
      {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Degree offered",
        ckrich: 'DegreeOfferedByUs',
      },
      {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Eligibility",
        ckrich: "EligibilityCriteria",
      },
      {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Fees ",
        ckrich: "Fees",
      },
      {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Careers",
        ckrich: "CareerImpact",
      },
      {
        __typename: "ComponentCommonHeading",
        enabled: true,
        text: "Faculty",
        ckrich: "MeetFacultySection",
      },
    ],
  },
  Overview: {
    __typename: "ComponentPagesImageWithInfo",
    enabled: true,
    Heading: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Enhance your career with India's premier online MBA With Campus Immersion (For Executives)",
    },
    Description: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "The MBA with Campus Immersion (For Executives) at Amity University Online is tailored for professionals with minimum 3 years of experience looking to elevate their careers while balancing their professional commitments. If you're driven to lead, innovate, and make an impact in today's dynamic business world, this program is designed for you. Blending online learning with 16 on-campus immersions, and 1 residency, it combines flexibility with hands-on experience. Gain practical knowledge through business case studies, global insights, and real-world projects while building a strong network with peers and industry leaders. Plus, earn a prestigious certificate from a top US or UK university, giving you a competitive edge.",
      text2:"Whether aiming for leadership roles or enhancing your expertise, this MBA empowers you to excel globally."
    },

    Image: {
      __typename: "ComponentCommonImage",
      source: {
        __typename: "UploadFileRelationResponseCollection",
        data: [
          {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Web_Banners_new_2_1_fbd4baee4d.webp",
            },
          },
        ],
      },
      alt: "overview-mba",
      enabled: true,
      title: "overview-mba",
    },

    MobileImage: {
      __typename: "ComponentCommonImage",
      source: {
        __typename: "UploadFileRelationResponseCollection",
        data: [
          {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/MOBILE_Banners_new_3c9b8be05d.webp",
            },
          },
        ],
      },
      alt: "overview-mba",
      enabled: true,
      title: "overview-mba",
    },
  },
  ProgramHighlights: {
    __typename: "ComponentPagesAdvantages",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Holistic Career Services: How we help you build your dream career",
    },
    Subtitle: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Our wide range of services are designed to meet your specific needs for career",
    },
    ImageList: [
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "Career exploration tools",
        Subtitle:
          "- Assessing your interests and strengths\n- Exploring different career options and pathways\n- Identifying your passion and potential",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_sb/student_class_looking_course_2_e2422e8a21_547b5eb689_8445486951.webp",
              alternativeText: null,
              name: "student_class_looking_course_2_e2422e8a21_547b5eb689.webp",
            },
          },
        },
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "Resume/CV assistance",
        Subtitle:
          "- Crafting professional resumes and CVs\n- Customizing application for specific industries\n- Highlighting your skills and achievements effectively",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_sb/networking_4994274f80.webp",
              alternativeText: null,
              name: "networking.webp",
            },
          },
        },
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "Virtual job search support",
        Subtitle:
          "- Guiding through the online application process\n- Networking support via virtual events and platforms\n- Preparing for virtual interviews to establish a professional online presence",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_sb/student_a910f63987.webp",
              alternativeText: null,
              name: "student.webp",
            },
          },
        },
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "Career counselling & advising",
        Subtitle:
          "- 1-on-1 sessions with experienced counsellors\n- Personalized career assessments and goal setting\n- Guidance on making informed decisions",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/front_view_stacked_books_earth_globe_open_book_pencils_education_day_1_9fa23e9918.webp",
              alternativeText: null,
              name: "front-view-stacked-books-earth-globe-open-book-pencils-education-day 1.webp",
            },
          },
        },
      },
    ],
  },
  AmityOnlineAdvantages: {
    __typename: "ComponentPagesSectionWithImage",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Why Choose Amity Online?",
    },
    Subtitle: null,
    ImageList: [
      {
        __typename: "ComponentPagesImageWithInfo",
        Title: {
          __typename: "ComponentCommonHeading",
          enabled: true,
          text: "Top Indian & Global \nFaculty",
        },
        Subtitle: {
          __typename: "ComponentCommonHeading",
          enabled: true,
          text: "Get a world-class education from the faculty with rich academic and industry experience.",
        },
        Image: {
          __typename: "ComponentCommonImage",
          enabled: true,
          alt: "img-1",
          title: "img-1",
          url: null,
          target: null,
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  url: "https://amityonline.com/_s/Group_48099322_1_577e541dd0_b19a416e3b.webp",
                },
              },
            ],
          },
        },
      },
      {
        __typename: "ComponentPagesImageWithInfo",
        Title: {
          __typename: "ComponentCommonHeading",
          enabled: true,
          text: "Career Services for \nJob Readiness",
        },
        Subtitle: {
          __typename: "ComponentCommonHeading",
          enabled: true,
          text: "Take master classes for interview preparation & resume building sessions while pursuing your degree.",
        },
        Image: {
          __typename: "ComponentCommonImage",
          enabled: true,
          alt: "img-2",
          title: "img-2",
          url: null,
          target: null,
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  url: "https://amityonline.com/_s/Group_48099322_2_29998660f5_312ea57103.webp",
                },
              },
            ],
          },
        },
      },
      {
        __typename: "ComponentPagesImageWithInfo",
        Title: {
          __typename: "ComponentCommonHeading",
          enabled: true,
          text: "Choose the way you\nwant to learn",
        },
        Subtitle: {
          __typename: "ComponentCommonHeading",
          enabled: true,
          text: "Enhance your learning potential through your \nchoice of printed books, audio books, e-books, \nvideos, and campus library resources.",
        },
        Image: {
          __typename: "ComponentCommonImage",
          enabled: true,
          alt: "img-3",
          title: "img-3",
          url: null,
          target: null,
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  url: "https://amityonline.com/_s/Group_48099322_3_8285f11c85_8257d03526.webp",
                },
              },
            ],
          },
        },
      },
      {
        __typename: "ComponentPagesImageWithInfo",
        Title: {
          __typename: "ComponentCommonHeading",
          enabled: true,
          text: "Placement \nAssistance",
        },
        Subtitle: {
          __typename: "ComponentCommonHeading",
          enabled: true,
          text: "Ensure a smooth job transition with placement opportunities from over 500+ hiring partners across domains.",
        },
        Image: {
          __typename: "ComponentCommonImage",
          enabled: true,
          alt: "img-4",
          title: "img-1",
          url: null,
          target: null,
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  url: "https://amityonline.com/_s/Group_48099322_4_38c933a489_c39f56dcc0.webp",
                },
              },
            ],
          },
        },
      },
    ],
  },
  HCLTechAdvantage: null,
  AccreditationRecognition: {
    __typename: "ComponentPagesSectionWithList",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Accreditations & recognitions",
    },
    Subtitle: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Endorsements of Excellence, Recognitions and Accreditations \nCelebrating Academic Quality of Amity Online",
    },
    ImageList: [
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/Group_48099254_1_46922cfc87_373b0aebce.webp",
              },
            },
          ],
        },
        alt: "awards-1",
        enabled: true,
        title: "India's only Online MBA ranked by \n QS: Asia Pacific Top 10",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/UGC_c7308364f3_0c92cb6f17.webp",
              },
            },
          ],
        },
        alt: "awards-2",
        enabled: true,
        title:
          "First university in India to receive UGC approval for Online Degrees and Learning Platform",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/WASC_fb7127e339.webp",
              },
            },
          ],
        },
        alt: "awards-3",
        enabled: true,
        title: "The Western Association\nof Schools and Colleges",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/Group_48099252_1_df96398b17_7a9d37adb4.webp",
              },
            },
          ],
        },
        alt: "award-4",
        enabled: true,
        title: "WES Accredited in Canada",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/Group_48100135_1_132c0a9195_8215e4ff75.webp",
              },
            },
          ],
        },
        alt: "awards-6",
        enabled: true,
        title: "Ranked 32 by National Institutional Ranking Framework ",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/the_62b2a634db_eedf54d391.webp",
              },
            },
          ],
        },
        alt: "awards-7",
        enabled: true,
        title: "Ranked among Top 3% Globally",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/AICTE_6b131890a9_dbd7744c02.webp",
              },
            },
          ],
        },
        alt: "awards-8",
        enabled: true,
        title: "Degree programs approved by All India Council of Technical Education",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/DEC_Main_Dark_72dpi_7b970c0a32_78e05c7964.webp",
              },
            },
          ],
        },
        alt: "awards-9",
        enabled: true,
        title: "India's first private university to be a member",
      },
    ],
  },
  CourseCurriculum: {
    __typename: "ComponentProgramDetailsCurriculum",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Program Overview & Structure",
    },
    CTA: {
      __typename: "ComponentCommonButtonWithImg",
      text: "Explore Curriculum",
      enabled: true,
      Icon: {
        __typename: "UploadFileEntityResponse",
        data: {
          __typename: "UploadFileEntity",
          attributes: {
            __typename: "UploadFile",
            url: "https://amityonline.com/_s/Group_48099358_122a6f7697.png",
            alternativeText: null,
            name: "Group 48099358.png",
          },
        },
      },
    },
    PerSemCurriculum: [
      {
        __typename: "ComponentCommonHeadingAndContent",
        heading: "Semester 1",
        subtitle:
          "- Accounting for Managers\n- Managerial Economics \n- Marketing Management \n- Statistics for Management\n- Professional communication \n",
        enabled: true,
      },
      {
        __typename: "ComponentCommonHeadingAndContent",
        heading: "Semester 2",
        subtitle:
          "- Business Research Methods \n- Financial Management \n- Human Resource Management \n- Legal Aspects of Business \n- Conflict Resolution and Management \n- Organizational Behaviour \n\n\n\n",
        enabled: true,
      },
      {
        __typename: "ComponentCommonHeadingAndContent",
        heading: "Semester 3",
        subtitle:
          "- Strategic Management\n- Management of Financial Services\n- Strategic Human Resource Management\n- Product and Brand Management\n- Operations Planning, Scheduling and Control\n- Professional Ethics\n- Minor Project\n",
        enabled: true,
      },
      {
        __typename: "ComponentCommonHeadingAndContent",
        heading: "Semester 4",
        subtitle:
          "- Big Data Analytics\n- Digital Commerce -Reinventing Business Models\n- Digital Marketing\n- Management in Action - Social Economic and Ethical Issues\n- Major Project\n",
        enabled: true,
      },
    ],
  },
  DegreeOfferedByUs: {
    __typename: "ComponentPagesImageWithInfo",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "",
    },
    Subtitle: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "The MBA with Campus Immersion (for Executives) at Amity University Online is tailored for seasoned individuals with minimum 3 years of experience. The program combines practical learning through case studies and projects with global insights, while fostering networking with peers and industry leaders.",
    },
    Image: {
      __typename: "ComponentCommonImage",
      source: {
        __typename: "UploadFileRelationResponseCollection",
        data: [
          {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_sb/MBA_399327e11d.webp",
            },
          },
        ],
      },
      alt: "degree-offered",
      enabled: true,
      title: "Sample Degree -",
    },
  },
  Specialization: null,
  MinorSpecialization: null,
  ClassHighlights: null,
  EligibilityCriteria: {
    __typename: "ComponentProgramDetailsCriteria",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Eligibility Criteria",
    },
    Subtitle: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "- Professionals with minimum 3 years of work experience looking to accelerate their careers.\n- At least 50% in your graduation degree.\n- Selection involves submitting a detailed Statement of Purpose (SOP)\n- Individuals aspiring to develop strategic leadership and decision making skills.\n- Suitable for individual seeking meaningful industry connections and cross functional expertise.\n-Ideal for individuals aiming for career growth in Management, Finance, Marketing, Sales, Operations, Logistics, and other dynamic fields.\n- Available for students residing in India only.",
    },
    Icon: {
      __typename: "ComponentCommonImage",
      source: {
        __typename: "UploadFileRelationResponseCollection",
        data: [
          {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://imagicom.in/design/mba-immersion1/images/Group_48100151_c379ede5cc_9cb075b091.png",
            },
          },
        ],
      },
      alt: "eligibility",
      enabled: true,
      title: "eligibility",
    },
    TypeWiseCriteria: [
      {
        __typename: "ComponentCommonHeadingAndContent",
        Heading: "For Indian Students",
        Content:
          "- A minimum of 3 years of work experience is required.\n- Must have achieved at least 50% marks in your graduation degree.\n- Adequate understanding of English is necessary to successfully engage with the program’s coursework and discussions.",
        enabled: true,
      },
      {
        __typename: "ComponentCommonHeadingAndContent",
        Heading: "For Foreign Students",
        Content:
          "- Candidates must have completed a graduation degree.\n- Certificate of equivalence from the Association of Indian Universities is required from students with foreign education to apply to any university in India. (Refer to http://aiu.ac.in/).",
        enabled: true,
      },
    ],
  },
  Fees: {
    __typename: "ComponentProgramDetailsFees",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Fees Structure",
    },
    LeftLink: {
      __typename: "ComponentCommonLink",
      text: "*Click to see EMI options",
      url: "#",
      target: null,
      title: null,
      enabled: true,
    },
    Description: null,
    CTA: {
      __typename: "ComponentCommonLink",
      text: "Scholarships Available: Enquire Now",
      url: "#",
      target: null,
      title: null,
      enabled: false,
    },
    FeesOptions: [
      {
        __typename: "ComponentProgramDetailsFeesItem",
        enabled: true,
        SecondaryHeading: "24 Months Zero Cost EMI",
        Heading: "₹15,835",
        Type: "Emi",
        HeadingDescription: "Interest free",
        BGColor: "#1A325D",
        TextColor: "#fff",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Frame_1000001899_2_437f090901_8d104d7813_7820022597.png",
              name: "Frame_1000001899_2_437f090901.webp",
              alternativeText: null,
            },
          },
        },
        FeesInInr: "₹15,835",
        FeesInUSD: null,
        Country: "IN",
      },
      {
        __typename: "ComponentProgramDetailsFeesItem",
        enabled: true,
        SecondaryHeading: "Per Semester Fee",
        Heading: "₹95,000",
        Type: "PerSemester",
        HeadingDescription: null,
        BGColor: "#FFFFFF",
        TextColor: "#1A325D",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Frame_1000001968_0ff53522df_b0e1afe985_66f52d787e.png",
              name: "Frame_1000001968_0ff53522df.webp",
              alternativeText: null,
            },
          },
        },
        FeesInInr: "₹95,000",
        FeesInUSD: "$1250",
        Country: "IN",
      },
      {
        __typename: "ComponentProgramDetailsFeesItem",
        enabled: true,
        SecondaryHeading: "Full Course Fee",
        Heading: "Full Course Fee",
        Type: "Full",
        HeadingDescription: null,
        BGColor: "#fff",
        TextColor: "#1A325D",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Frame_1000001969_801b8412c7_5fbe000c68_34f821a5aa.png",
              name: "Frame_1000001969_801b8412c7.webp",
              alternativeText: null,
            },
          },
        },
        FeesInInr: "₹3,80,000",
        FeesInUSD: "$5000",
        Country: null,
      },
    ],
  },
  AdmissionProcess: {
    __typename: "ComponentProgramDetailsAdmission",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Admission Process",
    },
    AdmissionStep: [
      {
        __typename: "ComponentProgramDetailsAdmissionStep",
        enabled: true,
        Title: "01. Select your\nprogram",
        Subtitle: "Choose the program that \nsuits your goals",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Group_48100511_ee8e218674_b8a2ff8c7b.webp",
              name: "Group_48100511_ee8e218674.webp",
              alternativeText: null,
            },
          },
        },
      },
      {
        __typename: "ComponentProgramDetailsAdmissionStep",
        enabled: true,
        Title: "02. Complete your\napplication",
        Subtitle: "Fill out your application with all the necessary information",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Group_48100512_2eda748696_6f054941b7.webp",
              name: "Group_48100512_2eda748696.webp",
              alternativeText: null,
            },
          },
        },
      },
      {
        __typename: "ComponentProgramDetailsAdmissionStep",
        enabled: true,
        Title: "03. Pay your\nprogram fee",
        Subtitle: "Make your program payment \nsecurely & easily",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Group_48100513_ab28110901_ef7ae586cf.webp",
              name: "Group_48100513_ab28110901.webp",
              alternativeText: null,
            },
          },
        },
      },
      {
        __typename: "ComponentProgramDetailsAdmissionStep",
        enabled: true,
        Title: "04. Submit &\nregister",
        Subtitle: "Submit your application and \ncomplete your registration",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Group_48100514_717f07b14d_4f03dbe2d1.webp",
              name: "Group_48100514_717f07b14d.webp",
              alternativeText: null,
            },
          },
        },
      },
      {
        __typename: "ComponentProgramDetailsAdmissionStep",
        enabled: true,
        Title: "05. Await Enrollment details",
        Subtitle: "Wait for enrollment \ndetails & further guidance",
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Group_48100515_344aaae406_b5ffed7b3a.webp",
              name: "Group_48100515_344aaae406.webp",
              alternativeText: null,
            },
          },
        },
      },
    ],
  },
  JourneryForGraduation: {
    __typename: "ComponentProgramDetailsJourney",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Journey Towards: Online Degree Program",
    },
    Image: {
      __typename: "ComponentCommonImage",
      source: {
        __typename: "UploadFileRelationResponseCollection",
        data: [
          {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_sb/journey_51705273af.webp",
            },
          },
        ],
      },
      alt: "journeyforeducation",
      title: "journeyforeducation",
      enabled: true,
    },
    Features: [
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "Online Orientation",
        Subtitle:
          "Attend orientation sessions to familiarize yourself with the program, university policies, and resources.\n",
        enabled: true,
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Frame_1000001923_96e58e1423_8eb42f1d79.webp",
            },
          },
        },
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "On-Campus Meet",
        Subtitle: "Meet your peers and faculty at an on-campus event.",
        enabled: true,
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Frame_1000001923_96e58e1423_8eb42f1d79.webp",
            },
          },
        },
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "Academic Progress",
        Subtitle:
          "Attend live and recorded classes, complete your assignments, and maintain a strong academic performance.",
        enabled: true,
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Frame_1000001923_96e58e1423_8eb42f1d79.webp",
            },
          },
        },
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "Utilize Resources",
        Subtitle:
          "Avail library resources at the Noida campus, online learning resources, and academic support services to enhance your learning.",
        enabled: true,
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Frame_1000001923_96e58e1423_8eb42f1d79.webp",
            },
          },
        },
      },
    ],
  },
  CareerImpact: {
    __typename: "ComponentProgramDetailsCareerImpact",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Career Services: How we help you build your dream career ",
    },
    Impacts: [
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "01. Industry Mentors",
        Subtitle: "Personalized guidance from\ncareer experts throughout \nthe academic journey.",
        enabled: true,
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Group_48099322_5_83d4ff011f_0e02ae7bbf.webp",
              name: "Group_48099322_5_83d4ff011f.webp",
              alternativeText: null,
            },
          },
        },
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "02. Virtual Placement Drives",
        Subtitle:
          "Connect with employers & \nexplore opportunities through \ninteractive online events.",
        enabled: true,
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Group_48099322_6_d7697ecc09_3fdc1fe03c.webp",
              name: "Group_48099322_6_d7697ecc09.webp",
              alternativeText: null,
            },
          },
        },
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "03. Master Classes",
        Subtitle:
          "Access comprehensive help \nfor resume building, interview\npreparation and job placement.",
        enabled: true,
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Group_48100099_b61ec01896_746d9f3811.webp",
              name: "Group_48100099_b61ec01896.webp",
              alternativeText: null,
            },
          },
        },
      },
      {
        __typename: "ComponentPagesAdvantageItem",
        Title: "04. Profile Building ",
        Subtitle:
          "Build a credible professional profile by showcasing your skills through hands-on projects.",
        enabled: true,
        Image: {
          __typename: "UploadFileEntityResponse",
          data: {
            __typename: "UploadFileEntity",
            attributes: {
              __typename: "UploadFile",
              url: "https://amityonline.com/_s/Group_48099322_7_b4ea241570_bd194db15d.webp",
              name: "Group_48099322_7_b4ea241570.webp",
              alternativeText: null,
            },
          },
        },
      },
    ],
  },
  PotentialJobRoles: {
    __typename: "ComponentProgramDetailsPotentialJob",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Potential Job roles",
    },
    Roles: [
      {
        __typename: "ComponentCommonHeadingAndContent",
        Heading: "Mid Level",
        Content:
          "-Marketing Manager \n-Human Resource Manager\n-Project Manager \n-Finance Manager\n-Operations Manager \n-Product Manager \n",
        enabled: true,
      },
      {
        __typename: "ComponentCommonHeadingAndContent",
        Heading: "Advanced Level",
        Content:
          "-Director of Operations \n-Chief Financial Officer (CFO) \n-Senior Product Manager \n-Chief Executive Officer (CEO) \n-Head of Marketing \n-Chief Human Resource Officer\n",
        enabled: true,
      },
    ],
  },
  HiringParteners: {
    __typename: "ComponentCommonImagesWIthHeading",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Hiring partners",
    },
    Images: [
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/image_10108_3417a22159.webp",
              },
            },
          ],
        },
        alt: "flipkart",
        enabled: true,
        url: null,
        target: null,
        title: "flipkart",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/image_10109_75f928e3ce.webp",
              },
            },
          ],
        },
        alt: "tech-mahindra",
        enabled: true,
        url: null,
        target: null,
        title: "tech-mahindra",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/image_10110_5ba54d6144.webp",
              },
            },
          ],
        },
        alt: "sbi",
        enabled: true,
        url: null,
        target: null,
        title: "sbi",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/image_10111_58cfa85aba.webp",
              },
            },
          ],
        },
        alt: "hdfc",
        enabled: true,
        url: null,
        target: null,
        title: "hdfc",
      },
      {
        __typename: "ComponentCommonImage",
        source: {
          __typename: "UploadFileRelationResponseCollection",
          data: [
            {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                url: "https://amityonline.com/_s/image_10166_e16f144320.png",
              },
            },
          ],
        },
        alt: "factspan",
        enabled: true,
        url: null,
        target: null,
        title: "factspan",
      },
    ],
  },
  Testimonials: {
    __typename: "ComponentPagesTitleWithSubTitle",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Hear what our students have to say",
    },
    SubTitle: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Discover student learning experiences\nand journeys at Amity Online",
    },
  },
  MeetFacultySection: {
    __typename: "ComponentPagesTitleWithSubTitle",
    enabled: true,
    Title: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Meet our top-ranked faculty ",
    },
    SubTitle: {
      __typename: "ComponentCommonHeading",
      enabled: true,
      text: "Learn from the best at Amity Online with world-class \neducation and leading international faculty.",
    },
  },
  NextStepCareerBanner: {
    __typename: "ComponentPagesBannerWithCta",
    enabled: true,
    Heading: {
      __typename: "ComponentCommonHeading",
      text: "Are you ready to take the next step in your career ? ",
      enabled: true,
    },
    BannerImage: {
      __typename: "ComponentCommonImage",
      enabled: true,
      source: {
        __typename: "UploadFileRelationResponseCollection",
        data: [
          {
            __typename: "UploadFileEntity",
            id: "259",
            attributes: {
              __typename: "UploadFile",
              alternativeText: null,
              url: "https://amityonline.com/_s/medium_shot_graduate_student_1_054c237147.webp",
            },
          },
        ],
      },
    },
    CTA: {
      __typename: "ComponentCommonLink",
      text: "Enroll Now",
      url: "https://application.amityonline.com/admission/home/signup",
      target: null,
      title: null,
      enabled: true,
    },
  },
  FAQs: {
    "__typename": "ComponentPagesFaqs",
    "enabled": true,
    "QuestionAnswer": [
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "What is the MBA with Campus Immersion (For Executives)?",
            "QuestionCK": "",
            "Answer": "The MBA with Campus Immersion (For Executives) is a hybrid program that combines online coursework with on-campus residency and immersion sessions. It focuses on building leadership, strategic thinking, and cross-functional management skills through experiential learning and real-world business exposure.",
            "AnswerCK": null,
            "Type": "GENERAL"
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "What is the structure of the campus immersion component?",
            "QuestionCK": null,
            "Answer": "The program includes 1 comprehensive campus residency for orientation and networking, followed by 16 campus immersions (4 per semester) spread throughout the program. These sessions feature leadership workshops, case studies, live projects, and interactions with industry experts.",
            "AnswerCK": null,
            "Type": "ACADEMICS"
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "What are the Locations of the MBA with Campus Immersion (For Executives) Program?",
            "QuestionCK": null,
            "Answer": "Students can select from a range of Amity campuses for their immersion sessions, available at the following locations: Noida, Mumbai, Kolkata, Pune, Hyderabad, Bangalore, Ahmedabad, Chennai, Patna, Indore, and Jaipur. Students can attend the campus residency at the Noida location.",
            "AnswerCK": null,
            "Type": "ADMISSION"
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "How will I get admission confirmation?",
            "QuestionCK": null,
            "Answer": "Once your admission process is completed and enrollment number is generated, our support team will get in touch with you for admission confirmation. ",
            "AnswerCK": null,
            "Type": null
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "What kind of study material is given?",
            "QuestionCK": null,
            "Answer": "Semester wise videos are available in student zone and program books are also couriered at registered address. ",
            "AnswerCK": null,
            "Type": null
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "Where do I get the Assignments?",
            "QuestionCK": null,
            "Answer": "You can get the assignments in student zone.",
            "AnswerCK": null,
            "Type": null
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "When does the admission start in Amity University Online for all programs?",
            "QuestionCK": null,
            "Answer": "There are two sessions, one starts from 1st January to April and second starts from 1st July to September. ",
            "AnswerCK": null,
            "Type": null
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "Cancellation of admission",
            "QuestionCK": null,
            "Answer": "Cancellation of admission will be granted on the special approval from competent authority on case to case basis.",
            "AnswerCK": null,
            "Type": null
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "How to obtain the Admission Letter?",
            "QuestionCK": null,
            "Answer": "Once the enrollment number is generated, an e-mail will be sent to the learner with login details. This mail can be considered as admission letter.",
            "AnswerCK": null,
            "Type": null
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "What is Amity University Online?",
            "QuestionCK": null,
            "Answer": "Amity University Online is the online learning platform of Amity University, one of India's leading private universities. It\noffers a wide range of undergraduate, postgraduate, and diploma programs across various disciplines, delivered\nentirely online.\n",
            "AnswerCK": null,
            "Type": null
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": " What are the key benefits of the campus immersion sessions?",
            "QuestionCK": null,
            "Answer": "Campus immersion sessions provide hands-on learning, practical exposure through real-life projects, networking opportunities with peers and industry leaders, and access to expert faculty, all in a collaborative and engaging environment.",
            "AnswerCK": null,
            "Type": "ACADEMICS"
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "Who is this program designed for?",
            "QuestionCK": null,
            "Answer": "This program is ideal for professionals, early and mid-career managers, and aspiring leaders seeking to enhance their business acumen, leadership capabilities, and career prospects without interrupting their work schedules",
            "AnswerCK": null,
            "Type": "GENERAL"
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "Can I balance this program with a full-time job?",
            "QuestionCK": null,
            "Answer": "Yes, the program is designed for professionals, with flexible online modules and immersive campus sessions scheduled to minimize disruption to work commitments.",
            "AnswerCK": null,
            "Type": "GENERAL"
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "Can learners opt for a different campus for each of the 16 immersion sessions?",
            "QuestionCK": null,
            "Answer": "A learner can choose only one campus for immersions planned during the program. For all the academic activities and immersion classes, the learner needs to be committed to their initially chosen campus.",
            "AnswerCK": null,
            "Type": "ADMISSION"
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "What kind of support is available during the program?",
            "QuestionCK": null,
            "Answer": "Participants receive continuous support from dedicated program managers, access to faculty mentorship, career coaching, and resources for academic success and personal development.",
            "AnswerCK": null,
            "Type": "GENERAL"
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "What specializations are available in this program?",
            "QuestionCK": null,
            "Answer": " The MBA with Campus Immersion (For Executives) emphasizes cross-functional learning but may also offer domain-specific certifications or electives in areas like Finance, Marketing, Operations, or Business Analytics.",
            "AnswerCK": null,
            "Type": "ACADEMICS"
        },
        {
            "__typename": "ComponentFaqQa",
            "enabled": true,
            "Question": "How does this program enhance career prospects?",
            "QuestionCK": null,
            "Answer": "The program offers tailored career coaching, placement assistance, and skill-building workshops focused on leadership, problem-solving, and innovation, helping participants advance their careers or transition to new roles.",
            "AnswerCK": null,
            "Type": "ACADEMICS"
        },
        {
          "__typename": "ComponentFaqQa",
          "enabled": true,
          "Question": "Is there a different Program Curriculum for the MBA with Campus Immersion (For Executives) Program?",
          "QuestionCK": null,
          "Answer": "The MBA with Campus Immersion (For Executives) follows the same MBA Curriculum and exclusive On-Campus Immersion sessions across Amity India campuses, which include On-Campus Classes, Group Projects, Networking Events, Industry Connect Sessions, Project Work Guidance Sessions, etc.",
          "AnswerCK": null,
          "Type": "ACADEMICS"
      },
        
    ]
  }

,
  facultiesData: [
    {
      __typename: "FacultyEntity",
      id: "6",
      attributes: {
        __typename: "Faculty",
        Name: "Urmila Tatke",
        enabled: true,
        ShortDesc:
          "Urmila Tatke is a veteran professor specializing in Economics, International Trade, and Industrial Economics. With a career spanning over three decades, including leadership roles at Pune University, she has inspired generations of students. Holding an M.A. in Economics and a B.Ed., Urmila is dedicated to fostering a deep understanding of economic concepts and global trade dynamics.",
        Department: "International Faculty",
        Designation: "Professor of Economics and International Trade, 33 Years of Experience, and Academic Mentor",
        BorderColor: "#4194F4",
        Picture: {
          __typename: "ComponentCommonImage",
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "q1.webp",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/q1_514b1b86e6.webp",
                },
              },
            ],
          },
        },
        ActionIcon: [
          {
            __typename: "ComponentCommonResponsiveIcon",
            enabled: true,
            alt: "play",
            title: "play",
            target: null,
            url: null,
            isMobileViewIcon: false,
            source: {
              __typename: "UploadFileEntityResponse",
              data: {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "fac-play-laptop.svg",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/fac_play_laptop_d6fefc6b40.svg",
                },
              },
            },
          },
        ],
        Video: {
          __typename: "ComponentCommonVideoEmbed",
          enabled: true,
          URL: "https://player.vimeo.com/video/876259034?h=3b6f45a1e6&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479",
        },
      },
    },
    {
      __typename: "FacultyEntity",
      id: "3",
      attributes: {
        __typename: "Faculty",
        Name: "Ashok Kumar Dhingra",
        enabled: true,
        ShortDesc:
          "Ashok Kumar Dhingra brings over three decades of expertise in Engineering Design, Management, and Computational Research. A former senior leader in engineering, Ashok is pursuing his Ph.D. in Computational Musicology, integrating interdisciplinary knowledge to foster innovation. He holds an MBA and a B.Tech in Electrical Engineering and has been a pioneer in bridging engineering and management education.",
        Department: "Assistant Professor",
        Designation: "Professor of Engineering and Management, 30+ Years of Industry and Academic Experience, and Ph.D. Scholar",
        BorderColor: "#FF3E6C",
        Picture: {
          __typename: "ComponentCommonImage",
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "0I8A4048 1.png",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/0_I8_A4048_1_892178b2a2.png",
                },
              },
            ],
          },
        },
        ActionIcon: [
          {
            __typename: "ComponentCommonResponsiveIcon",
            enabled: true,
            alt: "play",
            title: "play",
            target: null,
            url: null,
            isMobileViewIcon: false,
            source: {
              __typename: "UploadFileEntityResponse",
              data: {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "fac-play-laptop.svg",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/fac_play_laptop_d6fefc6b40.svg",
                },
              },
            },
          },
        ],
        Video: {
          __typename: "ComponentCommonVideoEmbed",
          enabled: true,
          URL: "https://player.vimeo.com/video/876259413?h=776a97103e&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479",
        },
      },
    },
    {
      __typename: "FacultyEntity",
      id: "1",
      attributes: {
        __typename: "Faculty",
        Name: "Dr. V.V. Krishna Reddy ",
        enabled: true,
        ShortDesc:
          "Dr. V.V. Krishna Reddy is a seasoned academic with extensive expertise in Finance, Accounting, and Microfinance. His impactful research and teaching have guided countless students in mastering complex financial concepts. Holding a Ph.D. in Commerce, an MBA in Finance, and an M.Com., Dr. Krishna Reddy has earned widespread recognition for his contributions to the microfinance sector. ",
        Department: "Assistant Professor",
        Designation: "Professor of Finance and Microfinance, 25+ Years of Academic Excellence, and Microfinance Researcher",
        BorderColor: "#5DCA7B",
        Picture: {
          __typename: "ComponentCommonImage",
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "4.webp",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/4_5e42a2ff13.webp",
                },
              },
            ],
          },
        },
        ActionIcon: [
          {
            __typename: "ComponentCommonResponsiveIcon",
            enabled: true,
            alt: "show more",
            title: "show more",
            target: null,
            url: "/",
            isMobileViewIcon: false,
            source: {
              __typename: "UploadFileEntityResponse",
              data: {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "1.svg",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/1_2c84db3dc0.svg",
                },
              },
            },
          },
        ],
        Video: {
          __typename: "ComponentCommonVideoEmbed",
          enabled: true,
          URL: "https://player.vimeo.com/video/876259511?h=eff5d77829&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479",
        },
      },
    },
    {
      __typename: "FacultyEntity",
      id: "10",
      attributes: {
        __typename: "Faculty",
        Name: "Dr. S. Sivakamasundari",
        enabled: true,
        ShortDesc:
          "Dr. S. Sivakamasundari is a celebrated economist specializing in International Trade, Business Environment, and Logistics. With over 21 years of academic excellence and numerous publications in reputed journals, she has profoundly influenced global business education. Dr. Sivakamasundari holds a Ph.D. in Economics and is known for her groundbreaking research and engaging pedagogy.  ",
        Department: "Assistant Professor",
        Designation: "Professor of Economics and Trade, 21+ Years of Experience, and Renowned Researcher",
        BorderColor: "#5DCA7B",
        Picture: {
          __typename: "ComponentCommonImage",
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "Sachit Paliwal.png",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/Sachit_Paliwal_71d1a4df8d.png",
                },
              },
            ],
          },
        },
        ActionIcon: [
          {
            __typename: "ComponentCommonResponsiveIcon",
            enabled: true,
            alt: "show more",
            title: "show more",
            target: null,
            url: null,
            isMobileViewIcon: false,
            source: {
              __typename: "UploadFileEntityResponse",
              data: {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "Group 48100573.svg",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/Group_48100573_0db55887b1.svg",
                },
              },
            },
          },
        ],
        Video: {
          __typename: "ComponentCommonVideoEmbed",
          enabled: true,
          URL: "https://player.vimeo.com/video/879292487?h=e9d6a184f2&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479",
        },
      },
    },
  ],
  testimonials: [
    {
      __typename: "TestimonialEntity",
      attributes: {
        __typename: "Testimonial",
        enabled: true,
        Name: "Shravanya Yerramsetty",
        ShortDesc:
          "Hey, I am MBA graduate from Amity Online. Amity Online offered an incredible learning experience, allowing me to study from the comfort of my home with unmatched flexibility.",
        Department: "MBA",
        Designation: "Student",
        url_key: null,
        Picture: {
          __typename: "ComponentCommonImage",
          enabled: true,
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "Group 48100567.webp",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/Group_48100567_e4ba7c575d.webp",
                },
              },
            ],
          },
        },
      },
    },
    {
      __typename: "TestimonialEntity",
      attributes: {
        __typename: "Testimonial",
        enabled: true,
        Name: "Ajimsha Puthur Abdul Hameed",
        ShortDesc:
          "I'm Ajimsha from Oman, and completing my MBA from Amity Online was an incredible journey. Despite initial COVID concerns, the transition was seamless with excellent support. I'm proud to have referred two friends from Oman too.",
        Department: "MBA",
        Designation: "Student",
        url_key: null,
        Picture: {
          __typename: "ComponentCommonImage",
          enabled: true,
          source: {
            __typename: "UploadFileRelationResponseCollection",
            data: [
              {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "Group 48100565.webp",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/Group_48100565_a521a5ec52.webp",
                },
              },
            ],
          },
        },
      },
    },
  ],
  videoTestimonials: [
    {
      __typename: "VideoTestimonialEntity",
      attributes: {
        __typename: "VideoTestimonial",
        Name: "Raghuvendra Singh",
        enabled: true,
        url_key: "#",
        Department: "MBA",
        Designation: "Student",
        Rating: 3,
        TestimonialText: null,
        VideoEmbed: {
          __typename: "ComponentCommonVideoEmbed",
          URL: "https://player.vimeo.com/video/876268520?h=fb5a384c43&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479",
          enabled: true,
          PosterImage: {
            __typename: "UploadFileEntityResponse",
            data: {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                name: "Mask group (2).webp",
                alternativeText: null,
                url: "https://amityonline.com/_s/Mask_group_2_8ad9026ace.webp",
              },
            },
          },
        },
        ActionIcon: [
          {
            __typename: "ComponentCommonResponsiveIcon",
            enabled: true,
            alt: "play",
            title: "play",
            target: null,
            url: null,
            isMobileViewIcon: false,
            source: {
              __typename: "UploadFileEntityResponse",
              data: {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "vid_play_laptop_dfc3ba6b3e.svg",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/vid_play_laptop_dfc3ba6b3e_5cada2f5af.svg",
                },
              },
            },
          },
          {
            __typename: "ComponentCommonResponsiveIcon",
            enabled: true,
            alt: "Play",
            title: "Play",
            target: null,
            url: null,
            isMobileViewIcon: true,
            source: {
              __typename: "UploadFileEntityResponse",
              data: {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "video-play-mobile.svg",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/video_play_mobile_b081738065.svg",
                },
              },
            },
          },
        ],
      },
    },
    {
      __typename: "VideoTestimonialEntity",
      attributes: {
        __typename: "VideoTestimonial",
        Name: "Mirza Akram Baig",
        enabled: true,
        url_key: "#1",
        Department: "MBA",
        Designation: "Student",
        Rating: 5,
        TestimonialText: null,
        VideoEmbed: {
          __typename: "ComponentCommonVideoEmbed",
          URL: "https://player.vimeo.com/video/876268699?h=49bb654f22&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;progress_bar=1&amp;player_id=0&amp;app_id=58479",
          enabled: true,
          PosterImage: {
            __typename: "UploadFileEntityResponse",
            data: {
              __typename: "UploadFileEntity",
              attributes: {
                __typename: "UploadFile",
                name: "video-2.webp",
                alternativeText: null,
                url: "https://amityonline.com/_s/video_2_430840ccb9.webp",
              },
            },
          },
        },
        ActionIcon: [
          {
            __typename: "ComponentCommonResponsiveIcon",
            enabled: true,
            alt: "show more",
            title: "show more",
            target: "self",
            url: null,
            isMobileViewIcon: false,
            source: {
              __typename: "UploadFileEntityResponse",
              data: {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "vid_play_laptop_dfc3ba6b3e.svg",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/vid_play_laptop_dfc3ba6b3e_5cada2f5af.svg",
                },
              },
            },
          },
          {
            __typename: "ComponentCommonResponsiveIcon",
            enabled: true,
            alt: "Play",
            title: "Play",
            target: null,
            url: null,
            isMobileViewIcon: true,
            source: {
              __typename: "UploadFileEntityResponse",
              data: {
                __typename: "UploadFileEntity",
                attributes: {
                  __typename: "UploadFile",
                  name: "video-play-mobile.svg",
                  alternativeText: null,
                  url: "https://amityonline.com/_s/video_play_mobile_b081738065.svg",
                },
              },
            },
          },
        ],
      },
    },
  ],
  BasicDetails: {
    __typename: "ComponentProgramDetailsBasicDetails",
    Heading: "Master of Business Administration Campus Immersion Online",
    SpecializationText: null,
    enabled: true,
    url_key: "/mba-campus-immersion-online",
    ProgramType: "PG_Program",
    CardActionBGColor: "#FFC907",
    Title1: "QS",
    Title2: "RANKED",
    PROGRAM_ID: "440",
    Custom10PgType: "Campus Immersion",
    Brochure: {
      __typename: "UploadFileEntityResponse",
      data: {
        __typename: "UploadFileEntity",
        attributes: {
          __typename: "UploadFile",
          url: "https://amityonline.com/_s/MBA_with_Campus_Immersion_for_Executives_da61e9935b.pdf",
        },
      },
    },
  },
  structuredData: {
    "@graph": [
      {
        "@id": "https://amityonline.com/master-of-business-administration-online",
        url: "https://amityonline.com/master-of-business-administration-online",
        name: "Online MBA Degree | Online MBA Program | Amity University Online",
        "@type": "WebPage",
        isPartOf: {
          "@id": "https://amityonline.com/#website",
        },
        breadcrumb: {
          "@id": "https://amityonline.com/master-of-business-administration-online#breadcrumb",
        },
        inLanguage: "en-us",
        description:
          "Online MBA degree from Amity University Online. A complete online course designed to provide best online education to professionals. Learn from most experienced faculty",
        dateModified: "2023-11-22T07:23:48+00:00",
        datePublished: "2023-11-21T03:45:23+00:00",
        potentialAction: [
          {
            "@type": "ReadAction",
            target: ["https://amityonline.com/master-of-business-administration-online"],
          },
        ],
      },
      {
        "@id": "https://amityonline.com/master-of-business-administration-online#breadcrumb",
        "@type": "BreadcrumbList",
        itemListElement: [
          {
            item: "https://www.amityonline.com/",
            name: "Home",
            "@type": "ListItem",
            position: 1,
          },
          {
            name: "Master of Business Administration",
            "@type": "ListItem",
            position: 2,
          },
        ],
      },
      {
        "@id": "https://www.amityonline.com/#website",
        url: "https://www.amityonline.com/",
        name: "Amity University Online",
        "@type": "WebSite",
        publisher: {
          "@id": "https://www.Amityonline.com/#organization",
        },
        inLanguage: "en-us",
        description: " Amity Online ",
        potentialAction: [
          {
            "@type": "SearchAction",
            target: {
              "@type": "EntryPoint",
              urlTemplate: "https://www.Amityonline.com/?s={search_term_string}",
            },
            "query-input": "required name=search_term_string",
          },
        ],
      },
      {
        "@id": "https://www.amityonline.com/#organization",
        url: "https://www.amityonline.com/",
        logo: {
          "@id": "https://www.amityonline.com/#/schema/logo/image/",
          url: "https://amityonline.com/images/logo.png",
          "@type": "ImageObject",
          width: 150,
          height: 67,
          caption: "Amity Online",
          contentUrl: "https://amityonline.com/images/logo.png",
          inLanguage: "en-us",
        },
        name: "Amity Online",
        "@type": "Organization",
        image: {
          "@id": "https://www.amityonline.com/#/schema/logo/image/",
        },
        sameAs: [
          "https://www.facebook.com/AmityOnlineUniversity/",
          "https://www.instagram.com/amityonline/",
          "https://www.youtube.com/AmityUniversityOnline",
          "https://twitter.com/amity_online",
        ],
      },
    ],
    "@context": "https://schema.org",
  },
  NewBannerUI: {
    __typename: "ComponentSpecializationNewBannerUi",
    id: "34",
    enabled: true,
    CTAColorMobile: "#1a325d",
    CTAColorDesktop: "#fff",
    tagTextHTML: "",
    CTABackgroundColorDesktop: "#1a325d",
    CTABackgroundColorMobile: "#ffc907",
    bannerImage: {
      __typename: "UploadFileEntityResponse",
      data: {
        __typename: "UploadFileEntity",
        attributes: {
          __typename: "UploadFile",
          url: "https://amityonline.com/_s/banner_bg_bdfecf05f7_0d9d1e91c7.jpg",
        },
      },
    },
    tagImage: {
      __typename: "UploadFileEntityResponse",
      data: null,
    },
  },
  
  MarqueeTextUI: null,
  StripTextUI: null,
};


export const associatedPrograms=[
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "46",
              "Heading": "Bachelor of Computer Applications with specialization in Data Engineering",
              "ProgramType": "UG_Program",
              "SpecializationText": "Specialization in Data Engineering",
              "PROGRAM_ID": "241",
              "Custom10PgType": "HARPU-HCL"
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "57",
              "Heading": "Master of Arts - Psychology",
              "ProgramType": "PG_Program",
              "SpecializationText": "Specialisation in Psychology",
              "PROGRAM_ID": "617",
              "Custom10PgType": "HARPU-Jaipur"
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "16",
              "Heading": "Master of Arts (Journalism and Mass Communication)",
              "ProgramType": "PG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "246",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "17",
              "Heading": "Master of Arts (Public Policy & Governance)",
              "ProgramType": "PG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "608",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "10",
              "Heading": "Master of Business Administration",
              "ProgramType": "PG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "440",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "14",
              "Heading": "Bachelors of Arts",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "244",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "41",
              "Heading": "Bachelors of Arts (General) Hindi Medium",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "244",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "39",
              "Heading": "Bachelors of Arts (General) in Kannada",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "244",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "37",
              "Heading": "Bachelors of Arts (General) in Malayalam",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "244",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "38",
              "Heading": "Bachelors of Arts (General) in Tamil",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "244",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "40",
              "Heading": "Bachelors of Arts (General) in Telugu",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "244",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "15",
              "Heading": "Bachelor of Arts (Journalism and Mass Communication)",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "242",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "3",
              "Heading": "Bachelor of Business Administration",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "240",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "13",
              "Heading": "Bachelor of Commerce",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "243",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "58",
              "Heading": "Bachelor of Commerce Honours",
              "ProgramType": "UG_Program",
              "SpecializationText": "Honours",
              "PROGRAM_ID": "612",
              "Custom10PgType": "HARPU-Jaipur"
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "11",
              "Heading": "Bachelor of Computer Applications",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "241",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "45",
              "Heading": "Bachelor of Business Administration with specialization in Data Analytics",
              "ProgramType": "UG_Program",
              "SpecializationText": "Specialization in Data Analytics",
              "PROGRAM_ID": "240",
              "Custom10PgType": "HARPU-HCL"
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "52",
              "Heading": "BCA WITH SPECIALIZATION IN DATA ENGINEERING",
              "ProgramType": "UG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "241",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "47",
              "Heading": "Bachelor of Computer Applications with specialization in Software Engineering",
              "ProgramType": "UG_Program",
              "SpecializationText": "Specialization in Software Engineering",
              "PROGRAM_ID": "241",
              "Custom10PgType": "HARPU-HCL"
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "18",
              "Heading": "Master of Commerce (Financial Management)",
              "ProgramType": "PG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "245",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "12",
              "Heading": "Master of Computer Applications",
              "ProgramType": "PG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "515",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "56",
              "Heading": "Master of Science (Data Science)",
              "ProgramType": "PG_Program",
              "SpecializationText": "Data Science",
              "PROGRAM_ID": "613",
              "Custom10PgType": "HARPU-Jaipur"
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "54",
              "Heading": "MCA  WITH SPECIALIZATION IN CYBER SECURITY",
              "ProgramType": "PG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "515",
              "Custom10PgType": null
          }
      }
  },
  {
      "__typename": "ProgramDetailsPageEntity",
      "attributes": {
          "__typename": "ProgramDetailsPage",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "49",
              "Heading": "Master of Computer Applications",
              "ProgramType": "PG_Program",
              "SpecializationText": "Specialization in Software Engineering",
              "PROGRAM_ID": "515",
              "Custom10PgType": "HARPU-HCL"
          }
      }
  },
  {
      "__typename": "SpecializationEntity",
      "attributes": {
          "__typename": "Specialization",
          "BasicDetails": {
              "__typename": "ComponentProgramDetailsBasicDetails",
              "id": "28",
              "Heading": "Master of Computer Applications with specialization in Blockchain Technology and Management",
              "ProgramType": "PG_Program",
              "SpecializationText": null,
              "PROGRAM_ID": "515",
              "Custom10PgType": null
          }
      }
  }
]

export const immersionProgram=[
  {
    "__typename": "ProgramDetailsPageEntity",
    "attributes": {
            "BasicDetails": {
              "id":"59",
              "Heading": "Master of Business Administration Campus Immersion Online",
              "SpecializationText": "Campus Immersion (For Executives)",
              "enabled": true,
              "url_key": "/mba-campus-immersion-online",
              "ProgramType": "PG_Program",
              "CardActionBGColor": "#FFC907",
              "Title1": "QS",
              "Title2": "RANKED",
              "PROGRAM_ID": "440",
              "Custom10PgType": "Campus Immersion (For Executives)"
            }
          }
}
]
